enum EMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

enum EHttpBodyType {
  NONE = 'none',
  FORMDATA = 'form-data',
  XWWWFORMURLENCODED = 'x-www-form-urlencoded',
  RAW = 'raw',
  BINARY = 'binary',
  GRAPHQL = 'graphql'
}

enum EHttpLanguageType {
  JSON = 'json',
  JAVASCRIPT = 'javascript',
  TEXT = 'text',
  HTML = 'html',
  XML = 'xml'
}

enum EHttpReturnType {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  OBJECT = 'object',
  ARRAY = 'array'
}


enum EComponentType {
  None = 'none',
  Button = 'button',
  Switch = 'switch',
  Label = 'label',
  Slider = 'slider',
  Fader = 'fader',
  SliderLinear = 'sliderLinear',
  ButtonsArray = 'buttonsArray',
  Container = 'container'
}

enum EComponentSize {
  Small = 'small',
  Medium = 'medium',
  Long = 'long',
  Large = 'large',
}

enum EUserType {
  Admin = 'admin',
  Installer = 'installer',
  Client = 'client'
}

enum EServiceType {
  Rest = 'rest',
  Websocket = 'websocket',
  Osc = 'osc',
  Mqtt = 'mqtt'
}

enum EWebsocketType {
  Buffer = "buffer",
  Utf = "utf",
  Bytes = "bytes",
  Frame = "frame",
  Number = "number"
}

enum EWebsocketProtocol {
  Echo = "echo-protocol",
  Arduino = "['arduino']"
}

enum EContainerType {
  Void = 'void',
  Radio = 'radio',
}

enum EImageType {
  DashboardBG = 'dashboardBG',
  IconButton = 'iconbutton'
}

enum ERequestType {
  SHARE = 'share',
  MESSAGE = 'message'
}

enum ERequestSubtype {
  DASHBOARD = 'dashboard',
  COMPONENT = 'component',
  SERVICE = 'service',
}

enum ERequestStatus {
  PENDING = 'pending',
  REFUSED = 'refused',
  ACCEPTED = 'accepted',
  COMPLETED = 'completed'
}

enum ERequestStatusColor {
  PENDING = '#999931',
  REFUSED = '#9c3434',
  ACCEPTED = '#309230',
  COMPLETED = '#4242b5'
}

interface INotificationRequest {
  _id: any
  status: ERequestStatus
  from: string
  to: string
  type: ERequestType
  subtype: ERequestSubtype
  idObject: string
  label: string
  subrequests: INotificationRequest[]
  lastEdit?: string
  creationDate?: string
}


interface IPresetComponent {
  id: string,
  value: any
}

interface IPresetDashboard {
  id: string,
  components: IPresetComponent[]
}

interface IPreset {
  name: string;
  presets: IPresetDashboard[];
}

interface IImage {
  _id: any
  name: string
  alt: string
  src: string
  ext: string
  uriFull?: string
  ord?: number,
  cover?: boolean,
  label?: string,
  type?: EImageType
}

interface ISession {
  token: string;
  refresh_token: string;
  tokenStart: number;
  tokenDuration: number;
}

interface IUser {
  _id: any,
  firstname: string;
  lastname: string;
  username: string;
  hash?: string;
  role?: string;
  token?: string;
  refresh_token?: string;
  tokenStart?: number;
  tokenDuration?: number;
  sessions?: ISession[],
  images?: IImage[]
}


interface IQuery {
  key: string;
  type: string;
  value?: any;
  options?: string[]
  min?: number | boolean;
  max?: number | boolean;
  step?: number;
  unitOfMeasure?: string;
}
interface IRequest {
  endpoint: string
  method: EMethod,
  header?: string[],
  karrayNotificationPath?: string,
  query?: IQuery[],
  params?: IQuery[],
  body?: {
    mode: EHttpBodyType,
    raw: string,
    options: {
      raw: {
        lang: EHttpLanguageType
      }
    }
  },
  response: {
    languageType: EHttpLanguageType
    fieldToReturn: string,
    type: EHttpReturnType
  }
}

interface IService {
  _id: any,
  name: string
  description?: string
  brand?: string
  editable: boolean
  protocol: EServiceType
  host: string
  origin?: any //copied by
  restService?: IRestService,
  websocketService?: IWebsocketService,
  oscService?: IOscService,
  mqttService?: IMqttService,
  serviceType?: string,
  duration?: number,
  creator: IUser[],
  parent?: any
}

interface IRestService {
  requestState: IRequest
  requestSet: IRequest
}

interface IWebsocketService {
  websocketProtocol: EWebsocketProtocol,
  messageOut: {
    type: EWebsocketType,
    parseMetod: EHttpLanguageType
  },
  messageIn: {
    type: EWebsocketType,
    parseMetod: EHttpLanguageType
  }
}

interface IOscService {
  messageOut: IOscMessage
  messageIn: IOscMessage
}

interface IOscMessage {
  endpoint: string
  port: number
  message: string
  params: IQuery[]
}

interface IMqttService {
  broker: string,
  port: number,
  username?: string,
  password?: string,
  topic: string
}


interface IComponent {
  _id: any,
  name: string
  description: string
  editable: boolean
  masters?: string[],
  slaves?: string[],
  color: string,
  creator?: IUser[],
  value?: any,
  backgroundColor: string
  size?: EComponentSize
  type: EComponentType
  icon?: string
  brand?: string
  services?: IService[]
  components?: IComponent[]
  containerType?: EContainerType
  polling?: number //ms
  origin?: any, //copied by plugin id
  top: number,
  left: number,
  width: number,
  height: number,
  disabled: boolean,
  min?: number,
  max?: number,
  parent?: any
}

interface IDashboard {
  _id: any,
  name: string
  img?: IImage,
  description?: string
  lastEdit?: string
  backgroundColor?: string
  components: IComponent[]
  origin?: any
  brand?: string
  editable: boolean
  creator?: IUser[],
  disabled: boolean,
  parent?: any
}
interface IConfiguration {
  _id: any,
  name: string
  rev: string
  dashboards: IDashboard[]
  karray_serial: string[]
  presets: IPreset[]
}

interface IInstallation {
  _id: any,
  id_plant: number,
  name: string,
  description?: string,
  installer: IUser,
  clients?: IUser[],
  client?: IUser,
  configuration: IConfiguration,
  devices: IDevice[],
  lastEdit: string,
  creationDate: string
}

interface IDevice {
  _id: any,
  hostname: string,
  local_ip: string,
  serial?: string,
  product_model?: string,
  note?: string,
  img?: string
}


interface IMultiroom {
  name: string,
  masterZone: string,
  slaveZones: string[],
  behaviour: string[]
}
interface IPlantFull {
  client: IPlantUser;
  clients: IPlantUser[];
  installer: IPlantUser;
  plant: IPlant;
  role: string;
  installationName?: string;
}
interface IPlant {
  address: string;
  area: string;
  city: string;
  description: string;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  state: string;
}
interface IPlantUser {
  role: string;
  user: {
    email: string;
    first_name: string;
    is_enabled: boolean;
    last_name: string;
    platform_role: string;
  };
}

export {
  EMethod,
  EComponentType,
  EComponentSize,
  EUserType,
  EHttpBodyType,
  EHttpLanguageType,
  EHttpReturnType,
  EServiceType,
  EImageType,
  ERequestType,
  ERequestSubtype,
  ERequestStatus,
  ERequestStatusColor,
  type IImage,
  type IUser,
  type ISession,
  type IConfiguration,
  type IQuery,
  type IComponent,
  type IDashboard,
  type IService,
  type IRestService,
  type IWebsocketService,
  type IOscService,
  type IMqttService,
  type IRequest,
  type IInstallation,
  type IMultiroom,
  type IDevice,
  type IPlantFull,
  type IPlant,
  type IPlantUser,
  type IPreset,
  type INotificationRequest
}