import { FC } from "react";
import { Grid, Typography, IconButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IService, IComponent, IInstallation } from "../../common-interfaces/interfaces";
import { deepCopy } from "../../helpers/utils";
import { useOnSet } from "../../hooks/useOnSet";
import { selectedInstallationAtom } from "../../recoil/atoms";
import TooltipOverflow from "../common/TooltipOverflow";
import SelectServiceParams from "./editServices/SelectServiceParams";

const GridStyle = { boxSizing: 'border-box', my: '10px', borderBottom: ".5px solid var(--light_grey-color)" }
const IconButtonStyle = { position: 'absolute', right: '0', top: '0', padding: '0' }
const DeleteForeverIconStyle = { color: "#ccc" }
const formControlStyle = { "& .MuiFormLabel-root, .Mui-focused, .MuiInputBase-root": { color: "var(--light_grey-color) !important" } }
const SelectStyle = { "& .MuiSvgIcon-root": { color: "var(--light_grey-color) !important" } }

const ServiceRow: FC<{ service: IService, component: IComponent }> = ({ service, component }) => {
    const SET = useOnSet();

    const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);

    const handleChangeHost = async (value: string, service: IService) => {
        let tmpService = await deepCopy(service);
        tmpService.host = value;
        try {
            await SET.service(tmpService, component._id);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeleteService = async (_id: any) => {
        let tmpSelectedComponent = await deepCopy(component);
        tmpSelectedComponent.services = tmpSelectedComponent.services.filter((service: IService) => service._id !== _id);
        SET.comp(tmpSelectedComponent);
    }

    const callHandleDeleteService = () => handleDeleteService(service._id)

    return <Grid item container sx={GridStyle}>
        {/* service name */}
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} position={'relative'}>
            <Grid item xs={11}>
                <TooltipOverflow tooltipChildren={<Typography color={'var(--light_grey-color)'} textTransform={'uppercase'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" children={service.name} />} />
            </Grid>
            <Grid item xs={1}>
                <IconButton size="small" onClick={callHandleDeleteService} sx={IconButtonStyle}>
                    <DeleteForeverIcon sx={DeleteForeverIconStyle} />
                </IconButton>
            </Grid>
        </Grid>
        {/* service forms */}
        <Grid item container justifyContent={'space-between'} alignItems={'start'}>
            <Grid item xs={5}>
                {!selectedInstallation.devices?.length ?
                    <Typography textAlign={'center'} color={'#666'}>No device available, please add one</Typography> :
                    <FormControl size='small' variant="standard" fullWidth sx={formControlStyle}>
                        <InputLabel>Host</InputLabel>
                        <Select onChange={e => handleChangeHost(e.target.value, service)} value={service.host} sx={SelectStyle}>
                            {selectedInstallation?.devices.map(device =>
                                <MenuItem key={device._id} value={device.local_ip}>
                                    {device.hostname}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
            </Grid>
            <Grid item xs={6}>
                <SelectServiceParams service={service} component={component} />
            </Grid>
        </Grid>
    </Grid>
}

export default ServiceRow;